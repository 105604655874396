import React, { useState, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import { A11y, Autoplay, Keyboard, Navigation } from 'swiper/modules';
import { Link } from 'gatsby';

import 'swiper/css';
import 'swiper/css/a11y';
import 'swiper/css/keyboard';
import 'swiper/css/navigation';

//Component Imports
import { GatsbyImage } from 'gatsby-plugin-image';
import { PreHeader } from '../theme/typography';
import Button from './buttons';
import Container from './container';

//Styled Components
const StyledSwiper = styled(Swiper)`
  --slide-width: 270px;
  user-select: none;
  box-sizing: border-box;
  min-width: 100%;
  height: 350px;
  overflow: visible;
  padding-right: calc(100% - var(--slide-width));

  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: var(--slide-width);
    height: 100%;
    clip-path: polygon(10% 0, 100% 0%, 90% 100%, 0% 100%);
    margin-right: -16px;

    @media (min-width: 1000px) {
      h4 {
        transition: transform 0.25s ease-in-out;
      }

      &:hover {
        h4 {
          transform: translateY(-48px);
        }
        a {
          opacity: 1;
        }
      }
    }
  }

  @media (min-width: 800px) {
    --slide-width: 392px;
    height: 512px;

    .swiper-slide {
      margin-right: -24px;
    }
  }
`;

const SlideLoopWrapper = styled.div`
  display: none;
  position: absolute;
  object-fit: cover;
  inset: 0;
  z-index: 1;
  transition: opacity 0.5s ease;

  @media (min-width: 1024px) {
    display: block;
  }
`;

const StyledSwiperSlide = styled(SwiperSlide)`
  ${SlideLoopWrapper} {
    /* visibility: hidden; */
    opacity: 0;
  }

  &:hover {
    ${SlideLoopWrapper} {
      /* visibility: visible; */
      opacity: 1;
    }
  }
`;

const SlideLoop = styled.video`
  min-height: 100%;
  height: 100%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;

const SlideImage = styled(GatsbyImage)`
  position: absolute;
  object-fit: cover;
  inset: 0;
  filter: grayscale(100%);
  z-index: 0;
`;

//! This is hacky. On iOS (Tested Chrome & Safari) it's needed to stop SlideContent from flashing on every slide change
const testAnimation = keyframes`
 0%{
  opacity: 1;
 }
 100%{
  transform: 1;
 }
`;

const SlideContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
  padding: 16px 8px;

  align-items: flex-end;
  justify-content: flex-end;

  //! TODO: Remove this hack
  animation: 60s ${testAnimation} normal infinite;

  @media (min-width: 800px) {
    padding: 24px;
  }
`;

const DirectorLink = styled(Link)`
  position: absolute;
  width: 100%;
  height: 100%;
  inset: 0;
  cursor: grab;
`;

const GradientDiv = styled.div`
  position: absolute;
  inset: 0;
  background: linear-gradient(
    0deg,
    rgba(8, 39, 58, 0.9) 0%,
    rgba(255, 255, 255, 0) 65%
  );
  z-index: -1;
`;

const DirectorButton = styled(Button)`
  position: absolute;
  right: 64px;
  bottom: 32px;
  opacity: 0;
  transition: opacity 0.25s ease-in-out;
`;

const DirectorName = styled.h4`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
`;

const FirstName = styled(PreHeader)`
  color: var(--white);
  font-size: 32px;
  line-height: 36px;
  margin-right: 28px;

  @media (min-width: 800px) {
    font-size: 36px;
    line-height: 48px;
  }
`;

const LastName = styled(PreHeader)`
  color: var(--white);
  font-size: 40px;
  line-height: 40px;
  margin-right: 32px;

  @media (min-width: 800px) {
    font-size: 48px;
    line-height: 48px;
  }
`;

const CarouselNav = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 48px;
  margin-top: 32px;
`;

const IconBubble = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: var(--swiper-navigation-bubble-color);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  transition: opacity 0.2s ease;
`;

const IconSVG = styled.svg`
  width: 24px;
  height: 24px;
  stroke: var(--swiper-navigation-icon-color);
  fill: none;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
`;

const PrevButton = styled(IconBubble)`
  opacity: ${({ isBeginning }) => (isBeginning ? 0.2 : 1)};
  cursor: ${({ isBeginning }) => (isBeginning ? 'unset' : 'pointer')};

  &:hover {
    opacity: ${({ isBeginning }) => (isBeginning ? 0.2 : 0.7)};
  }
`;

const NextButton = styled(IconBubble)`
  opacity: ${({ isEnd }) => (isEnd ? 0.2 : 1)};
  cursor: ${({ isEnd }) => (isEnd ? 'unset' : 'pointer')};

  &:hover {
    opacity: ${({ isEnd }) => (isEnd ? 0.2 : 0.7)};
  }
`;

const DirectorsSlider = ({ directors, sliderNumber }) => {
  const [isEnd, setIsEnd] = useState(false);
  const [isBeginning, setIsBeginning] = useState(true);
  const vidLoopsRef = useRef([]);

  //? To account for pages with multiple sliders on them
  const NextButtonClassname = sliderNumber
    ? `nextButton${sliderNumber}`
    : `nextButton`;
  const PrevButtonClassname = sliderNumber
    ? `prevButton${sliderNumber}`
    : `prevButton`;

  const swiperParameters = {
    modules: [A11y, Autoplay, Keyboard, Navigation],
    slidesPerView: 'auto',
    // spaceBetween: -24,
    slideToClickedSlide: false,
    grabCursor: true,
    navigation: {
      enabled: true,
      prevEl: `.${PrevButtonClassname}`,
      nextEl: `.${NextButtonClassname}`,
    },
    autoplay: {
      enabled: true,
      pauseOnMouseEnter: true,
      disableOnInteraction: true,
    },
    keyboard: { enabled: true, onlyInViewport: true, pageUpDown: false },
    resistanceRatio: 0.6,
  };

  return (
    <>
      <Container>
        <StyledSwiper
          {...swiperParameters}
          onReachBeginning={() => setIsBeginning(true)}
          onReachEnd={() => setIsEnd(true)}
          onFromEdge={() => {
            setIsBeginning(false);
            setIsEnd(false);
          }}
        >
          {directors.map((director, index) => {
            return (
              director.firstName && (
                <StyledSwiperSlide
                  key={director.firstName}
                  onMouseOver={() => {
                    if (vidLoopsRef[index]) {
                      vidLoopsRef[index]
                        .play()
                        .then(() => (vidLoopsRef[index].playing = true));
                    }
                  }}
                  onMouseOut={() => {
                    if (vidLoopsRef[index] && vidLoopsRef[index].playing)
                      vidLoopsRef[index].pause();
                  }}
                >
                  {director.sizzleLoop && (
                    <SlideLoopWrapper>
                      <SlideLoop
                        ref={(el) => (vidLoopsRef[index] = el)}
                        muted
                        loop
                        playsInline
                        preload='none'
                        poster={director.sizzleLoop.poster.asset.url}
                      >
                        <source
                          src={director.sizzleLoop.webm.asset.url}
                          type='video/webm'
                        />
                        <source
                          src={director.sizzleLoop.mp4.asset.url}
                          type='video/mp4'
                        />
                      </SlideLoop>
                    </SlideLoopWrapper>
                  )}
                  <SlideImage
                    image={director.headshot.asset.gatsbyImageData}
                    alt={`${director.firstName} ${director.lastName}`}
                  />
                  <SlideContent>
                    <DirectorLink to={director.slug.current} />
                    <DirectorButton
                      label={'View Work'}
                      type={'light'}
                      arrow={true}
                      path={director.slug.current}
                    />
                    <DirectorName>
                      {director.lastName && (
                        <FirstName as={'span'}>
                          {director.firstName && director.firstName}
                        </FirstName>
                      )}
                      <LastName as={'span'}>
                        {director.lastName
                          ? director.lastName
                          : director.firstName}
                      </LastName>
                    </DirectorName>
                    <GradientDiv />
                  </SlideContent>
                </StyledSwiperSlide>
              )
            );
          })}
        </StyledSwiper>
      </Container>
      <Container>
        <CarouselNav>
          <PrevButton
            arrow
            className={PrevButtonClassname}
            isBeginning={isBeginning}
          >
            <IconSVG>
              <use href={`/icons/feather-sprite.svg#arrow-left`} />
            </IconSVG>
          </PrevButton>
          <NextButton arrow className={NextButtonClassname} isEnd={isEnd}>
            <IconSVG>
              <use href={`/icons/feather-sprite.svg#arrow-right`} />
            </IconSVG>
          </NextButton>
        </CarouselNav>
      </Container>
    </>
  );
};

export default DirectorsSlider;
