import React from 'react';
import { graphql, Link } from 'gatsby';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';

// Component Imports
import SEO from '../components/seo';
import Container from '../components/container';
import { Section } from '../components/page-elements';
import { PreHeader } from '../theme/typography';
import CenteredHeaderBlock from '../components/centeredHeaderBlock';
import CenteredTextBlock from '../components/centeredTextBlock';
import PageTextBlock from '../components/pageTextBlock';
import CTABlock from '../components/ctaBlock';
import BottomCut from '../components/bottomCut';
import AnimatedStripe from '../components/animatedStripe';
import DirectorsSlider from '../components/directorsSlider';
import Button from '../components/buttons';
import Spacer from '../components/spacer';

// Asset Imports
import Stripes09SVG from '../images/stripes/stripes09.inline.svg';
import Stripes01SVG from '../images/stripes/stripes01.inline.svg';

// Function Imports
import shuffleArray from '../helpers/shuffleArray';

// Styled Components
const RosterStripes = styled(AnimatedStripe)`
  position: absolute;
  top: -5%;
  right: -80%;
  width: 130%;
  height: 130%;
  pointer-events: none;
  transform: scaleX(-1);

  > svg > path:nth-child(3) {
    fill: var(--blue5) !important;
  }

  @media (min-width: 500px) {
    top: -15%;
  }

  @media (min-width: 800px) {
    top: 0%;
    right: -35%;
    width: 90%;
    height: 100%;
  }
  @media (min-width: 1100px) {
    width: 80%;
    height: 90%;
  }
`;

const RosteredTextBlock = styled(PageTextBlock)`
  margin-left: 32px;
  @media (min-width: 800px) {
    margin-left: 40px;
  }
`;

const FreelanceStripes = styled(AnimatedStripe)`
  position: absolute;
  top: 20%;
  left: -15%;
  width: 65%;
  height: auto;
  z-index: 1;
  pointer-events: none;

  > svg > path:nth-child(1) {
    fill: var(--blue3) !important;
  }
  > svg > path:nth-child(2) {
    fill: var(--blue5) !important;
  }
`;

const FreelanceDirectorsContainer = styled.ul`
  --flex-gap: 16px;
  --directors-per-row: 2;
  --border-radius: 8px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;

  gap: var(--flex-gap);

  @media (min-width: 400px) {
    --flex-gap: 24px;
  }
  @media (min-width: 600px) {
    --flex-gap: 40px;
  }
  @media (min-width: 1000px) {
    --directors-per-row: 3;
  }

  @media (min-width: 1400px) {
    --flex-gap: 40px;
    --directors-per-row: 4;
  }

  > * {
    flex-basis: calc(
      (100% - ((var(--directors-per-row) - 1) * var(--flex-gap))) /
        var(--directors-per-row)
    );
  }
`;

const DirectorLink = styled(Link)`
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 10;

  @media (min-width: 1000px) {
    display: none;
  }
`;

const FreelanceDirectorTile = styled.li`
  list-style: none;
  aspect-ratio: 255 / 360;
  position: relative;
  border-radius: var(--border-radius);
  max-width: 255px;
  z-index: 2;

  @media (min-width: 1000px) {
    h4 {
      transition: transform 0.25s ease-in-out;
    }

    &:hover {
      h4 {
        transform: translateY(-48px);
      }
      a {
        opacity: 1;
      }
    }
  }
`;

const Headshot = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: grayscale(100%);

  > img {
    border-radius: var(--border-radius);
  }
`;

const TileContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  z-index: 1;
  padding: 12px;
  align-items: flex-end;
  justify-content: flex-end;

  @media (min-width: 800px) {
    padding: 24px;
  }
`;

const GradientDiv = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(
    0deg,
    rgba(8, 39, 58, 0.9) 0%,
    rgba(255, 255, 255, 0) 65%
  );

  border-radius: var(--border-radius);
  z-index: -1;
`;

const DirectorButton = styled(Button)`
  position: absolute;
  right: 24px;
  bottom: 28px;
  opacity: 0;
  transition: opacity 0.25s ease-in-out;
`;

const DirectorName = styled.h4`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
`;

const FirstName = styled(PreHeader)`
  /* display: block; */
  color: var(--white);
  font-size: calc(4vw + 3px);
  line-height: calc(4vw + 3px);

  @media (min-width: 600px) {
    font-size: 24px;
    line-height: 24px;
  }
`;

const LastName = styled(PreHeader)`
  /* display: block; */

  color: var(--white);

  font-size: calc(5vw + 3px);
  line-height: calc(5vw + 3px);

  @media (min-width: 600px) {
    font-size: 32px;
    line-height: 32px;
  }
`;

const DirectorsPage = ({ data }) => {
  var rosteredDirectors = shuffleArray(
    data.allSanityDirector.nodes.filter(
      (director, index) => director.relationship == 'represented',
    ),
  );
  var freelanceDirectors = shuffleArray(
    data.allSanityDirector.nodes.filter(
      (director, index) => director.relationship == 'freelance',
    ),
  );

  return (
    <>
      <SEO title='Directors Roster' />
      <Helmet>
        <body className='light-nav' />
      </Helmet>
      <Section
        light
        cuts='bottomCut'
        style={{ marginTop: 'var(--site-header-height)', overflowX: 'clip' }}
      >
        <RosterStripes direction='left'>
          <Stripes09SVG />
        </RosterStripes>
        <Container>
          <CenteredHeaderBlock data={data.sanityDirectorsPage.headerBlock} />
          <Spacer size={96} />
          <RosteredTextBlock
            data={{
              preHeader: data.sanityDirectorsPage.rosterPreHeader,
              title: data.sanityDirectorsPage.rosterTitle,
            }}
          />
        </Container>
        <DirectorsSlider directors={rosteredDirectors} />
        <Spacer size={64} />
        <BottomCut dark />
      </Section>
      <Section dark cuts='topAndBottomCut'>
        <CenteredTextBlock
          data={{
            preHeader: data.sanityDirectorsPage.freelancePreHeader,
            title: data.sanityDirectorsPage.freelanceTitle,
          }}
        />
        <Spacer size={64} />
        <Container style={{ zIndex: 2 }}>
          <FreelanceDirectorsContainer>
            {freelanceDirectors.map((director, index) => (
              <FreelanceDirectorTile key={index}>
                <DirectorLink to={director.slug.current} />
                <Headshot
                  image={director.headshot.asset.gatsbyImageData}
                  alt={`${director.firstName} ${director.lastName}`}
                />
                <TileContent>
                  <GradientDiv />
                  <DirectorName>
                    {director.lastName && (
                      <FirstName as='span'>{director.firstName}</FirstName>
                    )}
                    {
                      <LastName as='span'>
                        {director.lastName
                          ? director.lastName
                          : director.firstName}
                      </LastName>
                    }
                  </DirectorName>
                  <DirectorButton
                    label={'View Work'}
                    type={'light'}
                    arrow={true}
                    path={director.slug.current}
                  />
                </TileContent>
              </FreelanceDirectorTile>
            ))}
          </FreelanceDirectorsContainer>
          <Spacer size={120} />
          <CenteredTextBlock
            data={data.sanityDirectorsPage.directorApplicationBlock}
          />
        </Container>
        <FreelanceStripes midPage direction='leftAndUp'>
          <Stripes01SVG />
        </FreelanceStripes>
        <BottomCut />
      </Section>
      <Section cuts='topAndBottomCut'>
        <Spacer size={48} />

        <Container>
          <CTABlock data={data.sanityDirectorsPage.ctaBlock} />
        </Container>
        <BottomCut dark />
      </Section>
    </>
  );
};

DirectorsPage.propTypes = {
  data: PropTypes.object, // TODO: Expand
};

// GraphQL Query
export const directorsPageQuery = graphql`
  {
    sanityDirectorsPage {
      headerBlock {
        ...SanityCenteredHeaderBlockFragment
      }
      rosterPreHeader
      rosterTitle
      freelancePreHeader
      freelanceTitle
      directorApplicationBlock {
        ...SanityCenteredTextBlockFragment
      }
      ctaBlock {
        ...SanityCTABlockFragment
      }
    }
    allSanityDirector {
      nodes {
        firstName
        lastName
        slug {
          current
        }
        headshot {
          ...SanityImageFragment
        }
        relationship
        sizzleLoop {
          ...SanityVideoLoopFragment
        }
      }
    }
  }
`;

export default DirectorsPage;
