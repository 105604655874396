import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';

//Component Imports
import {
  P1,
  P2,
  D2,
  PreHeader,
  Quote,
  ButtonText,
  Technical,
} from '../theme/typography';
import Button from '../components/buttons';
import SubPoint from './_subPoint';
import VideoModal from './videoModal';

//Asset Imports
import TickBubbleSVG from '../images/icons/tickBubble.inline.svg';

//Styled Components
const TextBlock = styled.div`
  color: var(--primary-text);
  max-width: 450px;
`;

const SecondaryHeader = styled(PreHeader)`
  margin-bottom: 4px;
  color: var(--secondary-text);
`;

const PrimaryHeader = styled(D2)`
  margin-bottom: 16px;
  text-transform: capitalize;
  color: var(--primary-text);
  text-wrap: balance;
`;

const BodyText = styled(P1)`
  margin-bottom: ${({ hasButtons }) => (hasButtons ? '24px' : '0')};
  color: var(--primary-text);
`;

const TicksContainer = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
  margin-bottom: 32px;
  max-width: 95%;

  @media (min-width: 750px) {
    margin-bottom: 48px;
  }
`;

const TickContainer = styled.div`
  display: flex;
  padding-right: 8px;
`;

const TickBubble = styled(TickBubbleSVG)`
  margin-right: 16px;
  min-width: 24px;
  > circle {
    fill: var(--tick-bubble-fill, red);
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
`;

const TestimonialContainer = styled.div`
  margin-top: 32px;
  color: var(--tertiary-text);
`;

const TestimonialDivider = styled.hr`
  border: none;
  width: 160px;
  height: 1px;
  background-color: var(--blue4);
  margin-bottom: 32px;
`;

const Testimonial = styled(Quote)`
  margin-bottom: 16px;
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ClientContainer = styled.div`
  align-items: center;
  margin-bottom: 24px;
`;

const Headshot = styled(GatsbyImage)`
  border-radius: 50%;
  width: 48px;
  height: 48px;
  margin-right: 16px;
`;

const ClientName = styled(ButtonText)`
  display: flex;
  flex-direction: column;

  @media (min-width: 500px) {
    display: unset;
  }
`;

const JobTitle = styled(Technical)`
  text-transform: none;
`;

const ClientLogo = styled.svg`
  max-width: 156px;
  max-height: 80px;
  height: 80px;
  fill: var(--blue4);
`;

const TwoPointContainer = styled.div`
  margin-top: 48px;
  display: flex;
  flex-direction: column;
  gap: 32px;

  @media (min-width: 500px) {
    flex-direction: row;
    gap: 48px;
  }
`;

const ModalWrapper = styled.div`
  /* Take it out of document flow */
  position: absolute;
  /* Just to overwrite the modal video plugins styles */
  .modal-video-movie-wrap {
    background: none;
  }

  .modal-video {
    background-color: rgba(0, 0, 0, 0.9);
  }

  .modal-video-close-btn {
    cursor: pointer;
  }
`;

const PageTextBlock = ({ data, ...props }) => {
  const {
    type,
    title,
    preHeader,
    body,
    bullet1,
    bullet2,
    bullet3,
    ctaButton,
    ctaButtonData,
    ctaLink,
    ctaLinkData,
    testimonial,
    testimonialRef,
    subPoint1,
    subPoint2,
  } = data;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = useCallback(
    () => setIsModalOpen((prevState) => !prevState),
    [],
  );

  return (
    <TextBlock {...props}>
      <SecondaryHeader>{preHeader}</SecondaryHeader>
      <PrimaryHeader>{title}</PrimaryHeader>
      {
        {
          primary: (
            <BodyText hasButtons={ctaButton || ctaLink}>{body}</BodyText>
          ),
          twoSubPoint: (
            <BodyText hasButtons={ctaButton || ctaLink}>{body}</BodyText>
          ),
          ticks: (
            <TicksContainer>
              <TickContainer>
                <TickBubble />
                <P2>{bullet1}</P2>
              </TickContainer>
              <TickContainer>
                <TickBubble />
                <P2>{bullet2}</P2>
              </TickContainer>
              <TickContainer>
                <TickBubble />
                <P2>{bullet3}</P2>
              </TickContainer>
            </TicksContainer>
          ),
        }[type]
      }

      <ButtonContainer>
        {ctaButton && (
          <Button
            label={ctaButtonData.label}
            type={ctaButtonData.type ? ctaButtonData.type : 'primary'}
            arrow={ctaButtonData.arrow ? true : false}
            path={ctaButtonData.path ? ctaButtonData.path : '/'}
          />
        )}
        {ctaLink && !ctaLinkData.videoModal && (
          <Button
            label={ctaLinkData.label}
            type={ctaLinkData.type ? ctaLinkData.type : 'link'}
            arrow={ctaLinkData.arrow ? true : false}
            path={ctaLinkData.path ? ctaLinkData.path : '/'}
          />
        )}
        {ctaLink && ctaLinkData.videoModal && (
          <>
            <Button
              label={ctaLinkData.label}
              type={ctaLinkData.type ? ctaLinkData.type : 'link'}
              arrow={ctaLinkData.arrow ? true : false}
              onClick={toggleModal}
            />
            <VideoModal
              video={ctaLinkData.video}
              isModalOpen={isModalOpen}
              onClose={() => setIsModalOpen(false)}
            />
          </>
        )}
      </ButtonContainer>

      {testimonial && (
        <TestimonialContainer>
          <TestimonialDivider />
          <Testimonial>{testimonialRef.quote}</Testimonial>
          <ClientContainer>
            {/* {testimonialRef.image &&
              testimonialRef.image.asset.gatsbyImageData && (
                <Headshot
                  image={testimonialRef.image.asset.gatsbyImageData}
                  alt='Client Headshot'
                />
              )} */}
            <ClientName>
              {testimonialRef.author}
              <JobTitle as='span'> / {testimonialRef.jobTitle}</JobTitle>
            </ClientName>
          </ClientContainer>
          <LogoContainer>
            <ClientLogo
              dangerouslySetInnerHTML={{
                __html: testimonialRef.client.svgLogo,
              }}
            />
          </LogoContainer>
        </TestimonialContainer>
      )}
      {type === 'twoSubPoint' && (
        <TwoPointContainer>
          <SubPoint data={subPoint1} />
          <SubPoint data={subPoint2} />
        </TwoPointContainer>
      )}
    </TextBlock>
  );
};

PageTextBlock.propTypes = {
  data: PropTypes.shape({
    type: PropTypes.oneOf(['primary', 'ticks', 'twoSubPoint']),
    title: PropTypes.string.isRequired,
    preHeader: PropTypes.string.isRequired,
    body: PropTypes.string,
    ctaButton: PropTypes.bool,
    ctaButtonType: PropTypes.string,
    ctaLink: PropTypes.bool,
    testimonial: PropTypes.bool,
  }),
};

PageTextBlock.defaultProps = {
  data: {
    type: 'primary',
    ctaButton: false,
    ctaLink: false,
    testimonial: false,
  },
};

export default PageTextBlock;
